import BillingHistoryTable from './primitives/BillingHistoryTable';
import H4 from 'primitives/Typography/Headings/H4';
import HeroContainer from 'components/Recurly/primitives/HeroContainer';
import HeroStyles from 'components/Recurly/primitives/Hero';
import HeroText from 'components/Recurly/primitives/HeroText';
import Logo from 'components/Recurly/primitives/Logo';
import RecurlyHOC from 'components/Recurly/RecurlyHOC';
import { Component } from 'react';
import { Invoice } from 'state/Profile/types';
import { isEmpty } from 'lodash-es';
import { navigate } from 'state/Routing/actions';
import { Navigate } from 'state/Routing/types';
import { RecurlyFormWrap } from 'components/Recurly';

export type ConnectedProps = {
  recurlyKey: string;
  invoices: [any];
};

type Props = ConnectedProps & {
  navigate: Navigate;
  requestRecurlyHistory: () => void;
};

function buildInvoiceRows(invoices: [Invoice]) {
  return invoices.map((invoice: Invoice) => {
    const total = (invoice.pricing.totalInCents / 100).toLocaleString('en-US', {
      currency: invoice.pricing.currency,
      style: 'currency',
    });

    return (
      <tr key={invoice.uuid}>
        <td>{new Date(invoice.dueDate).toLocaleDateString()}</td>
        <td>{invoice.id}</td>
        <td>{invoice.state}</td>
        <td>{total}</td>
      </tr>
    );
  });
}

let fetchedInvoices = false;

export default class RecurlyHistory extends Component<Props> {
  componentDidMount() {
    const { invoices, requestRecurlyHistory } = this.props;
    if (!fetchedInvoices && isEmpty(invoices)) {
      requestRecurlyHistory();
      fetchedInvoices = true;
    }
  }

  buildInvoiceRows() {
    const { invoices } = this.props;
    return invoices.map((invoice: Invoice) => {
      const total = (invoice.pricing.totalInCents / 100).toLocaleString(
        'en-US',
        {
          currency: invoice.pricing.currency,
          style: 'currency',
        },
      );

      return (
        <tr key={invoice.uuid}>
          <td>{new Date(invoice.dueDate).toLocaleDateString()}</td>
          <td>{invoice.id}</td>
          <td>{invoice.state}</td>
          <td>{total}</td>
        </tr>
      );
    });
  }

  render() {
    const { invoices, recurlyKey } = this.props;

    if (!recurlyKey && __CLIENT__) {
      return navigate({ path: '/404/' });
    }

    const heroText = 'Billing History';

    return (
      <RecurlyHOC>
        <HeroContainer transformValue="-13rem">
          <HeroStyles>
            <Logo />
            <HeroText>{heroText}</HeroText>
          </HeroStyles>
        </HeroContainer>

        <RecurlyFormWrap>
          <H4>View all your previous receipts below.</H4>
          <BillingHistoryTable>
            <thead>
              <tr>
                <th>Date</th>
                <th>Order Id</th>
                <th>Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{buildInvoiceRows(invoices)}</tbody>
          </BillingHistoryTable>
        </RecurlyFormWrap>
      </RecurlyHOC>
    );
  }
}
