import styled from '@emotion/styled';

const BillingHistoryTable = styled.table(({ theme }) => ({
  margin: '3rem 0',
  th: {
    height: '5rem',
    textAlign: 'left',
  },
  tr: {
    borderBottom: `1px solid ${theme.colors.gray.dark}`,
    height: '5rem',
  },
  width: '100%',
}));

export default BillingHistoryTable;
